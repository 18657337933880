
import { defineComponent, ref } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import SettingsDataFieldsFilter from '@/components/pages/contacts/settings/contact/SettingsDataFieldsFilter.vue'
import SettingsDataFieldsTable from '@/components/pages/contacts/settings/contact/SettingsDataFieldsTable.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    PageBlock,
    DetailsHero,
    SettingsDataFieldsFilter,
    SettingsDataFieldsTable,
  },
  setup() {
    const search = ref('')
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Settings',
        name: 'base.contacts.settings',
      },
      {
        label: 'Organization data fields',
      },
    ])

    const tableHeaders = ref([
      { text: 'Field name', value: 'title', width: '33%' },
      { text: 'Status', value: 'status' },
      { text: 'Organization profile view', cellClass: 'toggle-cell', value: 'view' },
      { text: 'Add organization modal', cellClass: 'toggle-cell', value: 'modal' },
      { text: 'Field type', value: 'type' },
    ])

    const tableItems = ref([
      {
        title: 'Name',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'text_fields', text: 'Text' },
      },
      {
        title: 'Domain',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'text_fields', text: 'Text' },
      },
      {
        title: 'Email',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'alternate_email', text: 'Email' },
      },
      {
        title: 'Phone',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'call', text: 'Phone' },
      },
      {
        title: 'Owner',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'account_circle', text: 'User' },
      },
      {
        title: 'Type',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: true,
        type: { icon: 'tmi-form', text: 'Dropdown' },
      },
      {
        title: 'Address',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'location_on', text: 'Address' },
      },
      {
        title: 'Primary contact',
        status: { name: 'system', color: 'blue', outline: true },
        view: false,
        modal: false,
        type: { icon: 'person', text: 'Contact' },
      },
      {
        title: 'Country',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-form', text: 'Dropdown' },
      },
      {
        title: 'Timezone',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-form', text: 'Dropdown' },
      },
      {
        title: 'Size',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-form', text: 'Dropdown' },
      },
      {
        title: 'Industry',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-form', text: 'Dropdown' },
      },
      {
        title: 'Social media',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'share', text: 'Social media' },
      },
      {
        title: 'Tags',
        status: { name: 'system', color: 'blue', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-form-textbox', text: 'Autocomplete' },
      },
      {
        title: 'Followers',
        status: { name: 'system', color: 'blue', outline: true },
        view: false,
        modal: false,
        type: { icon: 'account_circle', text: 'User' },
      },
      {
        title: 'Contacts',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: false,
        modal: false,
        type: { icon: 'tmi-123', text: 'Numerical' },
      },
      {
        title: 'Total interactions',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-123', text: 'Numerical' },
      },
      {
        title: 'Last interaction',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'event', text: 'Date' },
      },
      {
        title: 'Last outbound message',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'event', text: 'Date' },
      },
      {
        title: 'Last inbound message',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'event', text: 'Date' },
      },
      {
        title: 'Total conversations',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: false,
        modal: false,
        type: { icon: 'tmi-123', text: 'Numerical' },
      },
      {
        title: 'Open conversations',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: false,
        modal: false,
        type: { icon: 'tmi-123', text: 'Numerical' },
      },
      {
        title: 'ID',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'tmi-123', text: 'Numerical' },
      },
      {
        title: 'Created',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'event', text: 'Date' },
      },
      {
        title: 'Created by',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'account_circle', text: 'User' },
      },
      {
        title: 'Last updated',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'event', text: 'Date' },
      },
      {
        title: 'Last updated by',
        status: { name: 'read-only', color: 'gray', outline: true },
        view: true,
        modal: false,
        type: { icon: 'account_circle', text: 'User' },
      },
    ])

    return {
      breadcrumbsLink,
      tableHeaders,
      tableItems,
      search,
    }
  },
})
